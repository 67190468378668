<template>
	<w-list>
		<w-divider />
		<template v-for="service in value">
			<w-list-tile :key="service.id" avatar @click="selectable ? selectService(service) : undefined">
				<w-list-tile-avatar :color="service.category.color">
					<span class="white--text">{{ service.category.abbreviation }}</span>
				</w-list-tile-avatar>
				<w-list-tile-content>
					<w-list-tile-title v-text="service.title" />
					<w-list-tile-sub-title
						v-text="
							$t('projects.date_from_to', {
								from: $d(new Date(service.start)),
								to: $d(new Date(service.end))
							})
						"
					/>
				</w-list-tile-content>
				<w-list-tile-action>
					{{ $n(service.price, 'currency') }}
				</w-list-tile-action>
			</w-list-tile>
			<w-divider :key="`${service.id}-divider`" />
		</template>
	</w-list>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

export default {
	name: 'ServicesList',
	mixins: [ProjectsManagerModuleGuard],
	props: {
		selectable: {
			default: true,
			required: false,
			type: Boolean
		},
		value: {
			required: true,
			type: Array
		}
	},
	methods: {
		selectService: function (service) {
			const projectId = service.project_id
			return this.service.selectService(projectId, service.id)
		}
	}
}
</script>
